<template>

    <!-- <div>

        <div style="padding: 20px 15px;font-size: 15px;background: url(http://42.194.241.236:8091/profile/upload/2021/04/08/02beijing.png);color:#fff">
            <div style="line-height: 2.2;border-bottom: 1px solid #EEEEEE;padding-bottom: 10px;">
                <p style="font-weight:900;"><span style="font-size: 18px;">雷锋日记（六）</span></p>
                <p style="font-size:12px;"><span style="margin-right:30px;">声动课堂</span><span>2021-11-19</span></p>
            </div>
            <div style="padding: 30px 0;text-align: center;line-height: 2.5;font-weight:900;">
                <p><span style="font-size: 18px;">学习党的光辉历史</span><br>
                <span style="font-size: 18px;">汲取砥砺奋进力量</span><br>
                <span style="font-size: 18px;">望城烟草庆祝建党100周年</span><br>
                <span style="font-size: 18px;">系列活动之声动学党史</span></p><span style="font-size: 18px;">
                </span><p style="color:#DEA710;"><span style="font-size: 18px;">学党史 读经典 悟思想</span></p>
                <img style="width:200px;height:80px;margin:20px auto;" src="http://42.194.241.236:8091/profile/upload/2021/04/02/a2bbaa2e-622f-432d-b8e4-f569e5c4469a.png" />
                <p><span style="font-size: 18px;">雷锋家乡学雷锋</span></p>
                <p><span style="font-size: 18px;">雷锋精神永流传</span></p>
                <p><span style="font-size: 18px;">本期声动课堂让我们一起学习</span></p>
                <p style="color:#DEA710;"><span style="font-size: 18px;">雷锋日记（六）</span></p>
            </div> 
        </div>
        <div style="padding: 40px 15px 100px;font-size: 15px;background: url(http://42.194.241.236:8091/profile/upload/2021/04/08/02zhengwenbeijing.jpg);background-size:cover;background-repeat:no-repeat;">
            <div style="margin-bottom:10px;background-color: #FFF8E5;padding:10px;border-radius: 3px;">
                <div style="background-color: #F1F3F4;padding: 10px;">
                    <p style="font-weight:900;color:#0E0E0E;">雷锋日记（六）</p>
                    <div style="color:#5D5D5D;margin:10px 0;font-size:12px;overflow:hidden;">
                        <div style="float:left;margin-right:10px;height:32px;">来自:声动课堂</div>
                        <div style="display:flex;"><span>诵读者：</span><span>郑幸容</span></div>
                    </div>
                    <audio style="height:35px;width:100%;" src="http://42.194.241.236:8091/profile/upload/2021/11/19/11-19-06.mp3" controlslist="nodownload" controls autoplay></audio>
                </div>
            </div>  
            <div style="text-align: center;margin-top: 30px;">
                <div style="font-weight:900;font-size: 18px;"><img style="width:18px;height:18px;vertical-align:middle;margin-top:-5px;margin-right: 3px;" src="http://42.194.241.236:8091/profile/upload/2021/04/02/1ffe8c1c-1b10-4637-b479-b219bef50371.png">雷锋日记（六）<img style="width:18px;height:18px;vertical-align:middle;margin-top:-5px;margin-left: 2px;" src="http://42.194.241.236:8091/profile/upload/2021/04/02/1ffe8c1c-1b10-4637-b479-b219bef50371.png"></div>
            </div>
            <div style="line-height: 2;text-align: justify;font-size:15px;">
                <p style="text-align: center;"><img style="width:100%;margin: 20px auto 10px;" src="http://42.194.241.236:8091/profile/upload/2021/11/19/11-19-06-01.png" /></p>
                <p style="text-indent: 30px;">1962年4月19日</p>
                <p style="text-indent: 30px;">我今天看了《在前进的道路上》的电影后，受到了很大的教育。影片中的何局长因居功骄傲，组织观念不强，脱离了党的领导，脱离了群众，光凭自己的主观愿望办事，结果犯了严重的错误。</p>
                <p style="text-indent: 30px;">他犯错误的根源是什么呢？因为他骄傲自大，不尊重别人，不深入下层，凭主观办事，因此脱离群众；因为他不虚心学习，政治水平就跟不上形势的发展，对问题的看法和认识就有偏差，其结果必然犯错误。</p>
                <p style="text-indent: 30px;">事实教育了我，骄傲是犯错误的根源，是落后的开始。我永远要保持谦虚谨慎的态度，老老实实为党工作。</p>
                <p style="text-indent: 30px;">影片中罗副局长这个人物很好，表现在他政治立场坚定，原则性强，敢于批评斗争，虚心好学，能密切联系群众，对革命事业高度负责。</p>
                <p style="text-indent: 30px;">我要永远向他学习，多为党做些工作，为祖国做贡献。</p>
            </div>
            <div style="overflow:hidden;margin:30px 0;line-height:1.5;">
                <div style="float:right;">
                    <p style="margin-bottom:8px;overflow:hidden;"><span>郑幸容</span><span style="float:right;margin-left:15px;">中共望城区烟草专卖局<br>机关支部纪律委员</span></p>
                </div>
                <div style="font-weight:900;float:right;text-align:right;">诵读者：</div>
            </div>
            <div style="margin-top:50px;">
                <div style="width: 100%;height: 40px;display: flex;align-items: center;">
                    <div style="flex: 40%;height: 2px;background: #DEA710;"></div> 
                    <div style="padding: 0 10px;"><img style="width: 60px;height: 42px;display: inline-block;" src="http://42.194.241.236:8091/profile/upload/2021/04/02/bd13eee7-bf35-4773-b9a0-650b5f8ae25b.gif"></div>
                    <div style="flex: 40%;height: 2px;background: #DEA710;"></div>
                </div>
                <img style="width:100%;margin:40px auto 20px;" src="http://42.194.241.236:8091/profile/upload/2021/04/02/7f66b54a-df01-46b0-9f58-54f46a011ba2.png" />
                <img style="width:100%;" src="http://42.194.241.236:8091/profile/upload/2021/11/19/11-19-06-02.png" />
            </div>
        </div>

    </div> -->

    <!-- <div>
        <div style="padding: 20px 15px;font-size: 14px;">
            <div style="line-height: 2.2;border-bottom: 1px solid #EEEEEE;padding-bottom: 10px;margin-bottom:20px;">
                <p style="font-weight:900;">紫荆红色故事馆【第二期】</p>
                <p><span style="margin-right:30px;">声动课堂</span><span>2021-12-31</span></p>
            </div>
            <div style="background:#F6F6F6;">
                <div style="padding: 30px 0;text-align: center;line-height: 2.2;background: url(http://1.13.102.140:8091/profile/upload/2021/04/09/bg1.png);background-size:60%;background-repeat:no-repeat;margin-bottom:10px;">
                    <p>学习党的光辉历史<br>汲取砥砺奋进力量<br>长沙烟草<span style="color:#FF0000">学习党的十九届六中全会精神</span></p>
                    <p style="color:#FF0000;font-weight:900;font-size:14px;margin:10px auto 20px;">学党史 悟思想 办实事 开新局</p>
                    <img style="width:200px;" src="http://1.13.102.140:8091/profile/upload/2021/04/09/100.png" />
                </div>
            </div>
            <div style="margin-bottom:10px;background-color: #FFF8E5;padding:15px 0;">
                <p style="text-align:center;font-weight:900;margin-bottom:8px;">党的十九届六中全会精神</p>
                <div style="background-color: #F1F3F4;padding: 12px 10px;margin:0 12px 6px;">
                    <p style="font-weight:900;color:#0E0E0E;">一个根本问题</p>
                    <p style="color:#A4A4A4;margin:10px 0;font-size:12px;">来自声动课堂</p>
                    <audio style="height:35px;width:100%;" src="http://1.13.102.140:8091/profile/upload/2021/12/31/12-31-01.mp3" controlslist="nodownload" controls autoplay></audio>
                </div>
                <div style="padding:8px 25px 0;"><img style="width:100%;" src="http://1.13.102.140:8091/profile/upload/2021/12/31/12-31-01-01.png" /></div>
            </div>
            <div style="margin-bottom:10px;background-color: #FFF8E5;padding:15px 0;">
                <p style="text-align:center;font-weight:900;margin-bottom:8px;">党的十九届六中全会精神</p>
                <div style="background-color: #F1F3F4;padding: 12px 10px;margin:0 12px 6px;">
                    <p style="font-weight:900;color:#0E0E0E;">二个确立、马克思主义中国化的三次飞跃</p>
                    <p style="color:#A4A4A4;margin:10px 0;font-size:12px;">来自声动课堂</p>
                    <audio style="height:35px;width:100%;" src="http://1.13.102.140:8091/profile/upload/2021/12/31/12-31-02.mp3" controlslist="nodownload" controls></audio>
                </div>
                <div style="padding:8px 25px 0;"><img style="width:100%;" src="http://1.13.102.140:8091/profile/upload/2021/12/31/12-31-02-01.png" /></div>
            </div>
            <div style="margin-bottom:10px;background-color: #FFF8E5;padding:15px 0;">
                <p style="text-align:center;font-weight:900;margin-bottom:8px;">党的十九届六中全会精神</p>
                <div style="background-color: #F1F3F4;padding: 12px 10px;margin:0 12px 6px;">
                    <p style="font-weight:900;color:#0E0E0E;font-size:10px;">四个历史时期 四个伟大飞跃、五个方面总结百年历史意义</p>
                    <p style="color:#A4A4A4;margin:10px 0;font-size:12px;">来自声动课堂</p>
                    <audio style="height:35px;width:100%;" src="http://1.13.102.140:8091/profile/upload/2021/12/31/12-31-03.mp3" controlslist="nodownload" controls></audio>
                </div>
                <div style="padding:8px 25px 0;"><img style="width:100%;" src="http://1.13.102.140:8091/profile/upload/2021/12/31/12-31-03-01.png" /></div>
            </div>
            <div style="margin-bottom:10px;background-color: #FFF8E5;padding:15px 0;">
                <p style="text-align:center;font-weight:900;margin-bottom:8px;">党的十九届六中全会精神</p>
                <div style="background-color: #F1F3F4;padding: 12px 10px;margin:0 12px 6px;">
                    <p style="font-weight:900;color:#0E0E0E;font-size:10px;">十个“明确”概括习近平新时代中国特色社会主义思想核心内容（1～4）</p>
                    <p style="color:#A4A4A4;margin:10px 0;font-size:12px;">来自声动课堂</p>
                    <audio style="height:35px;width:100%;" src="http://1.13.102.140:8091/profile/upload/2021/12/31/12-31-04.mp3" controlslist="nodownload" controls></audio>
                </div>
                <div style="padding:8px 25px 0;"><img style="width:100%;" src="http://1.13.102.140:8091/profile/upload/2021/12/31/12-31-04-01.png" /></div>
            </div>
            <div style="margin-bottom:10px;background-color: #FFF8E5;padding:15px 0;">
                <p style="text-align:center;font-weight:900;margin-bottom:8px;">党的十九届六中全会精神</p>
                <div style="background-color: #F1F3F4;padding: 12px 10px;margin:0 12px 6px;">
                    <p style="font-weight:900;color:#0E0E0E;font-size:10px;">十个“明确”概括习近平新时代中国特色社会主义思想核心内容（5～8）</p>
                    <p style="color:#A4A4A4;margin:10px 0;font-size:12px;">来自声动课堂</p>
                    <audio style="height:35px;width:100%;" src="http://1.13.102.140:8091/profile/upload/2021/12/31/12-31-05.mp3" controlslist="nodownload" controls></audio>
                </div>
                <div style="padding:8px 25px 0;"><img style="width:100%;" src="http://1.13.102.140:8091/profile/upload/2021/12/31/12-31-05-01.png" /></div>
            </div>
            <div style="margin-bottom:10px;background-color: #FFF8E5;padding:15px 0;">
                <p style="text-align:center;font-weight:900;margin-bottom:8px;">党的十九届六中全会精神</p>
                <div style="background-color: #F1F3F4;padding: 12px 10px;margin:0 12px 6px;">
                    <p style="font-weight:900;color:#0E0E0E;font-size:10px;">十个“明确”概括习近平新时代中国特色社会主义思想核心内容（9～10）</p>
                    <p style="font-weight:900;color:#0E0E0E;font-size:10px;">十个“坚持”总结百年奋斗历史经验</p>
                    <p style="color:#A4A4A4;margin:10px 0;font-size:12px;">来自声动课堂</p>
                    <audio style="height:35px;width:100%;" src="http://1.13.102.140:8091/profile/upload/2021/12/31/12-31-06.mp3" controlslist="nodownload" controls></audio>
                </div>
                <div style="padding:8px 25px 0;"><img style="width:100%;" src="http://1.13.102.140:8091/profile/upload/2021/12/31/12-31-06-01.png" /></div>
            </div>
            <div style="margin-bottom:10px;background-color: #FFF8E5;padding:15px 0;">
                <p style="text-align:center;font-weight:900;margin-bottom:8px;">党的十九届六中全会精神</p>
                <div style="background-color: #F1F3F4;padding: 12px 10px;margin:0 12px 6px;">
                    <p style="font-weight:900;color:#0E0E0E;font-size:10px;">十三个方面总结新时代历史性成就和历史性变革（1～3）</p>
                    <p style="color:#A4A4A4;margin:10px 0;font-size:12px;">来自声动课堂</p>
                    <audio style="height:35px;width:100%;" src="http://1.13.102.140:8091/profile/upload/2021/12/31/12-31-07.mp3" controlslist="nodownload" controls></audio>
                </div>
                <div style="padding:8px 25px 0;"><img style="width:100%;" src="http://1.13.102.140:8091/profile/upload/2021/12/31/12-31-07-01.png" /></div>
            </div>
            <div style="margin-bottom:10px;background-color: #FFF8E5;padding:15px 0;">
                <p style="text-align:center;font-weight:900;margin-bottom:8px;">党的十九届六中全会精神</p>
                <div style="background-color: #F1F3F4;padding: 12px 10px;margin:0 12px 6px;">
                    <p style="font-weight:900;color:#0E0E0E;font-size:10px;">十三个方面总结新时代历史性成就和历史性变革（4～7）</p>
                    <p style="color:#A4A4A4;margin:10px 0;font-size:12px;">来自声动课堂</p>
                    <audio style="height:35px;width:100%;" src="http://1.13.102.140:8091/profile/upload/2021/12/31/12-31-08.mp3" controlslist="nodownload" controls></audio>
                </div>
                <div style="padding:8px 25px 0;"><img style="width:100%;" src="http://1.13.102.140:8091/profile/upload/2021/12/31/12-31-08-01.png" /></div>
            </div>
            <div style="margin-bottom:10px;background-color: #FFF8E5;padding:15px 0;">
                <p style="text-align:center;font-weight:900;margin-bottom:8px;">党的十九届六中全会精神</p>
                <div style="background-color: #F1F3F4;padding: 12px 10px;margin:0 12px 6px;">
                    <p style="font-weight:900;color:#0E0E0E;font-size:10px;">十三个方面总结新时代历史性成就和历史性变革（8～10）</p>
                    <p style="color:#A4A4A4;margin:10px 0;font-size:12px;">来自声动课堂</p>
                    <audio style="height:35px;width:100%;" src="http://1.13.102.140:8091/profile/upload/2021/12/31/12-31-09.mp3" controlslist="nodownload" controls></audio>
                </div>
                <div style="padding:8px 25px 0;"><img style="width:100%;" src="http://1.13.102.140:8091/profile/upload/2021/12/31/12-31-09-01.png" /></div>
            </div>
            <div style="margin-bottom:10px;background-color: #FFF8E5;padding:15px 0;">
                <p style="text-align:center;font-weight:900;margin-bottom:8px;">党的十九届六中全会精神</p>
                <div style="background-color: #F1F3F4;padding: 12px 10px;margin:0 12px 6px;">
                    <p style="font-weight:900;color:#0E0E0E;font-size:10px;">十三个方面总结撕时代历史性成就和历史性变革（11～13）</p>
                    <p style="color:#A4A4A4;margin:10px 0;font-size:12px;">来自声动课堂</p>
                    <audio style="height:35px;width:100%;" src="http://1.13.102.140:8091/profile/upload/2021/12/31/12-31-10.mp3" controlslist="nodownload" controls></audio>
                </div>
                <div style="padding:8px 25px 0;"><img style="width:100%;" src="http://1.13.102.140:8091/profile/upload/2021/12/31/12-31-10-01.png" /></div>
            </div>
        </div>
    </div> -->

    <div v-html="detail.content"></div>
</template>

<script>
import { defArticleDetail } from '@/api/request'
import wxapi from '@/api/wxapi'
export default {
    components: {},
    data() {
        return {
            image:require('@/assets/bg-red.png'),
            detail:''
        };
    },
    watch:{
        '$route':{
            handler(route){
                let that = this;
                if(route.name === 'classDetail'){
                    that.getDetail().then(() => wxapi.wxRegister(this.wxRegCallback))
                }
            },
            deep:true
        }
    },
    mounted() {
        this.getDetail().then(() => wxapi.wxRegister(this.wxRegCallback))
    },
    methods: {
        async getDetail() {
            let { data } = await defArticleDetail(this.$route.query.id)
            this.detail = data
            document.title = this.detail.title
        },
        wxRegCallback () {
            // 用于微信JS-SDK回调
            this.wxShareTimeline()
            this.wxShareAppMessage()
        },
        wxShareTimeline () {
        // 微信自定义分享到朋友圈
            let option = {
                title: this.detail.title, // 分享标题
                link: window.location.href, // 分享链接，根据自身项目决定是否需要split
                imgUrl: 'https://wcyc.nd-cohen.com/profile/upload/common/share.jpg', // 分享图标, 需要绝对路径
                success: () => {
                    // alert('分享成功')
                }
            }
            // 将配置注入通用方法
            wxapi.shareTimeline(option)
        },
        wxShareAppMessage () {
        // 微信自定义分享给朋友
            let option = {
                type:2,
                title: this.detail.title, // 分享标题
                desc: this.detail.abstractZy, // 分享描述
                link: window.location.href, // 分享链接，根据自身项目决定是否需要split
                imgUrl: 'https://wcyc.nd-cohen.com/profile/upload/common/share.jpg', // 分享图标, 需要绝对路径
                success: () => {
                    // alert('分享成功'+this.detail.id)
                }
            }
            // 将配置注入通用方法
            wxapi.shareAppMessage(option)
        },
    }
}
</script>

<style scoped>
.list_bg{
    padding: 20px 15px;
    font-size: 13px;
}
.title{
    line-height: 2.2;
    border-bottom: 1px solid #EEEEEE;
    padding-bottom: 10px;
}
.Opening{
    padding: 30px 0;
    text-align: center;
    line-height: 2.5;
}
.player{
    background-color: #FFF8E5;
    border-radius: 6px;
    padding: 12px 10px;
    color: #0E0E0E;
}
.illustration{
    text-align: center;
    margin: 60px 0 30px;
}
.content{
    line-height: 1.8;
    text-align: justify;
}
.content p{
    text-indent: 30px;
}
.forshow{ 
    width: 100%; 
    height: 40px; 
    display: flex; 
    align-items: center;
} 
.forshow .img_setH{ 
    padding: 0 10px; 
} 
.forshow .img_setH img{ 
    width: 50px; 
    height: 42px; 
    display: inline-block; 
} 
.forshow .flex{ 
    flex: 40%; 
    height: 2px; 
    background: #DEA710; 
}
</style>
